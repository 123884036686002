import { ai } from "@/plugins/axios"
import { parseJwt } from "@/utils/globalUtils"
import { defineStore } from "pinia"
export const useAuthStore = defineStore({
  id: "Auth",
  state: () => ({
    user: {},
    member_id: "",
    loggedInUsers: [],
    entityId: ""
  }),
  getters: {
    getCurrentMemberId: (state) =>
      state.member_id || localStorage.getItem("member_id")
  },
  actions: {
    storeUserInfo(userInfo) {
      this.user = userInfo
      this.member_id = userInfo.member_id
    },
    async refreshToken(refresh_token) {
      console.log(refresh_token)
      try {
        const response = await ai.post(
          "method/agenda_manager.api.auth.auth.refresh_access_token",
          {
            refresh_token: refresh_token
          }
        )
        console.log("refreshToken", response)
        const data = parseJwt(response.data.token)
        console.log(data)
        this.member_id = this.entityId
          ? this.getUserIdByEntityId(this.entityId)
          : data.member_id
        this.user = {
          ...data,
          token: response.data.token,
          exp: data.exp,
          refresh_token: response.data.refresh_token
        }
        console.log("user ferom strore", this.user)
        localStorage.setItem("user", JSON.stringify(this.user))
        localStorage.setItem("member_id", this.member_id)
        this.login({
          token: response.data.token,
          member_id: this.member_id,
          refresh_token: response.data.refresh_token,
          ...data
        })
        return response.data
      } catch (error) {
        console.error("Error refreshing token:", error)
        throw error
      }
    },
    getUserIdByEntityId(entityId) {
      this.entityId = entityId
      const usersWithEntityId = []
      for (const [user, entityIds] of Object.entries(this.user.linked_users)) {
        console.log(entityIds)
        if (entityIds.includes(entityId)) {
          usersWithEntityId.push(user)
        }
      }
      this.member_id = usersWithEntityId[0] || this.user.member_id
      localStorage.setItem("member_id", this.member_id)
      console.log(this.member_id)
      return usersWithEntityId[0]
    },
    saveUser(userInfo) {
      this.user = userInfo
    },
    login(userInfo) {
      let existingUsers = JSON.parse(localStorage.getItem("usersLogged")) || []
      console.log(existingUsers, "existingUsers")

      const userIndex = existingUsers.findIndex(
        (user) => user.member_id === userInfo.member_id
      )

      if (userIndex !== -1) {
        // Replace the existing user
        existingUsers[userIndex] = userInfo
      } else {
        // Add the new user
        existingUsers.push(userInfo)
      }

      this.loggedInUsers = existingUsers

      this.user = userInfo
      this.member_id = userInfo.member_id

      // before set check there is values
      // localStorage.setItem("user", JSON.stringify(userInfo))
      //       localStorage.setItem("usersLogged", JSON.stringify(existingUsers))
      if (userInfo) {
        localStorage.setItem("user", JSON.stringify(userInfo))
      }

      if (existingUsers.length > 0) {
        localStorage.setItem("usersLogged", JSON.stringify(existingUsers))
      }
    },
    logout() {
      console.log("logout")
      this.user = {}
      localStorage.removeItem("user")
    },
    setLoggedInUsers() {
      const savedLoggedUsers = JSON.parse(localStorage.getItem("usersLogged"))
      this.loggedInUsers = savedLoggedUsers
    },
    reSetAuthStoreFromLocal() {
      if (localStorage.getItem("user")) {
        console.log(localStorage.getItem("user"))
        this.user = JSON.parse(localStorage.getItem("user"))
        // this.member_id = this.user.member_id
      }
      if (localStorage.getItem("loggedInUsers")) {
        this.loggedInUsers = JSON.parse(localStorage.getItem("loggedInUsers"))
      }
    },
    removedFromSavedAcc(user) {
      const savedLoggedUsers = JSON.parse(localStorage.getItem("usersLogged"))
      const userIndex = savedLoggedUsers.findIndex(
        (savedUser) => savedUser.member_id === user.member_id
      )
      savedLoggedUsers.splice(userIndex, 1)
      localStorage.setItem("usersLogged", JSON.stringify(savedLoggedUsers))
      this.loggedInUsers = savedLoggedUsers
    }
  }
})
